import "ant-design-vue/es/modal/style/css";
import _Modal from "ant-design-vue/es/modal";
import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick, createVNode } from 'vue';
import useTablePagination from '@/hooks/pagination';
import Cookies from "js-cookie";
import { useStore } from 'vuex';
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { cloneDeep } from 'lodash-es';
import checkPayroll from './components/checkPayroll.vue';
import payrollPage from './components/payrollPage.vue';
import { selectPayrollByPagination, deleteHyPayroll, selectSalaryItem, deleteItemById, addOrUpdateSalaryItem } from '@/api/personnel'; //表头

const columns = [{
  title: '名称',
  dataIndex: 'payrollName'
}, {
  title: '发薪月份',
  dataIndex: 'salaryMonth'
}, {
  title: '发薪人数',
  dataIndex: 'salaryPeopleNum'
}, {
  title: '未发布',
  dataIndex: 'unpublishedNum'
}, {
  title: '已发布',
  dataIndex: 'publishedNum'
}, {
  title: '已撤回',
  dataIndex: 'withdrawnNum'
}, {
  title: '创建人',
  dataIndex: 'creator'
}, {
  title: '创建时间',
  dataIndex: 'createTime',
  width: 180,
  align: 'center'
}, {
  title: '备注',
  dataIndex: 'remark',
  slots: {
    customRender: 'remark'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    QuestionCircleOutlined,
    ExclamationCircleOutlined,
    checkPayroll,
    payrollPage
  },

  setup() {
    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    const formRef = ref();
    const formState = reactive({
      payrollName: null,
      salaryMonth: null
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([{}]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        payrollName: formState.payrollName ? formState.payrollName : null,
        salaryMonth: formState.salaryMonth
      };
      loading.value = true;

      try {
        let res = await selectPayrollByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    }; //薪资项管理


    const salaryVisible = ref(false);

    const handleSalary = async () => {
      try {
        let res = await selectSalaryItem();

        if (res.code === 200) {
          payableData.value = [];
          buckleData.value = [];

          if (res.data && res.data.length) {
            res.data.forEach((item, index) => {
              if (item.itemType === 0) {
                payableData.value.push({ ...item,
                  key: item.itemName
                });
              }

              if (item.itemType === 1) {
                buckleData.value.push({ ...item,
                  key: item.itemName
                });
              }
            });
          }

          salaryVisible.value = true;
        } else {
          _message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    }; //应付


    const payableData = ref([]);
    const editableDataPayable = reactive({});

    const editPayable = key => {
      editableDataPayable[key] = cloneDeep(payableData.value.filter(item => key === item.key)[0]);
    };

    const savePayable = async key => {
      editableDataPayable[key].itemName = editableDataPayable[key].itemName.replace(/\s*/g, "");

      if (!editableDataPayable[key].itemName) {
        return _message.error('名称不能为空');
      }

      try {
        let param = {
          id: editableDataPayable[key].id,
          itemName: editableDataPayable[key].itemName,
          itemType: 0
        };
        let res = await addOrUpdateSalaryItem(param);

        if (res.code === 200) {
          payableData.value.forEach(item => {
            if (item.key === key) {
              item.id = res.data.id;
              item.itemName = res.data.itemName;
              item.key = res.data.itemName;
            }
          });

          _message.success(res.message);

          delete editableDataPayable[key];
        } else {
          _message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const cancelPayable = key => {
      delete editableDataPayable[key];
    };

    const handleAddPayable = () => {
      payableData.value.push({
        itemName: "",
        key: payableData.value.length,
        id: null
      });
      editableDataPayable[payableData.value.length - 1] = {
        itemName: "",
        key: payableData.value.length,
        id: null
      };
      console.log(payableData.value);
    };

    const deletelPayable = async val => {
      if (val.id === null) {
        delete editableDataPayable[val.key];
        let inx = payableData.value.findIndex(item => item.key === val.key);
        payableData.value.splice(inx, 1); // payableData.value.splice(val.key,1)
      } else {
        const res = await deleteItemById({
          id: val.id
        });

        if (res.code === 200) {
          _message.success(res.message);

          let inx = payableData.value.findIndex(item => item.id === val.id);
          payableData.value.splice(inx, 1);
          delete editableDataPayable[val.key];
        } else {
          _message.error(res.message);
        }
      }
    }; //应扣


    const buckleData = ref([]);
    const editableDataBuckle = reactive({});

    const editBuckle = key => {
      editableDataBuckle[key] = cloneDeep(buckleData.value.filter(item => key === item.key)[0]);
    };

    const saveBuckle = async key => {
      editableDataBuckle[key].itemName = editableDataBuckle[key].itemName.replace(/\s*/g, "");

      if (!editableDataBuckle[key].itemName) {
        return _message.error('名称不能为空');
      }

      try {
        let param = {
          id: editableDataBuckle[key].id,
          itemName: editableDataBuckle[key].itemName,
          itemType: 1
        };
        let res = await addOrUpdateSalaryItem(param);

        if (res.code === 200) {
          buckleData.value.forEach(item => {
            if (item.key === key) {
              item.id = res.data.id;
              item.itemName = res.data.itemName;
              item.key = res.data.itemName;
            }
          });

          _message.success(res.message);

          delete editableDataBuckle[key];
        } else {
          _message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const cancelBuckle = key => {
      delete editableDataBuckle[key];
    };

    const handleAddBuckle = () => {
      buckleData.value.push({
        itemName: "",
        key: buckleData.value.length,
        id: null
      });
      editableDataBuckle[buckleData.value.length - 1] = {
        itemName: "",
        key: buckleData.value.length,
        id: null
      };
    };

    const deletelBuckle = async val => {
      if (val.id === null) {
        delete editableDataBuckle[val.key];
        let inx = buckleData.value.findIndex(item => item.key === val.key);
        buckleData.value.splice(inx, 1); // buckleData.value.splice(val.key,1)
      } else {
        const res = await deleteItemById({
          id: val.id
        });

        if (res.code === 200) {
          let inx = buckleData.value.findIndex(item => item.id === val.id);
          buckleData.value.splice(inx, 1);
          delete editableDataBuckle[val.key];

          _message.success(res.message);
        } else {
          _message.error(res.message);
        }
      }
    };

    const handleClosePayable = () => {
      if (Object.keys(editableDataPayable).length || Object.keys(editableDataBuckle).length) {
        _Modal.confirm({
          title: '当前页存在未保存信息，确认放弃更改吗?',
          icon: createVNode(ExclamationCircleOutlined),

          onOk() {
            Object.keys(editableDataPayable).map(key => delete editableDataPayable[key]);
            Object.keys(editableDataBuckle).map(key => delete editableDataBuckle[key]);
            salaryVisible.value = false;
          },

          // onCancel() {
          //   console.log('取消');
          // },
          class: 'test'
        });
      } else {
        salaryVisible.value = false;
      }
    }; //创建工资单


    const checkPayrollVisible = ref(false);

    const handleSetPayroll = () => {
      checkPayrollVisible.value = true;
    };

    const closeCheckPayroll = val => {
      checkPayrollVisible.value = val;
      getTableData();
    }; //查看工资单


    const payrollState = reactive({
      isShowPayrollPage: false,
      payrollName: null,
      salaryMonth: null,
      id: null
    });

    const toView = val => {
      payrollState.isShowPayrollPage = true;
      payrollState.payrollName = val.payrollName;
      payrollState.salaryMonth = val.salaryMonth;
      payrollState.id = val.id;
      console.log(payrollState);
    };

    const closePayrollPage = val => {
      payrollState.isShowPayrollPage = val;
      getTableData();
    }; //删除


    const onDelete = async id => {
      try {
        const res = await deleteHyPayroll({
          id
        });

        if (res.code === 200) {
          _message.success(res.message);

          getTableData();
        } else {
          _message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const belongProject = Number(Cookies.get("belongProject"));
    onMounted(() => {
      getTableData();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      toView,
      isCompanyAdmin,
      handleSalary,
      salaryVisible,
      payableData,
      editableDataPayable,
      editPayable,
      savePayable,
      cancelPayable,
      handleAddPayable,
      handleClosePayable,
      buckleData,
      editableDataBuckle,
      editBuckle,
      saveBuckle,
      cancelBuckle,
      handleAddBuckle,
      handleSetPayroll,
      checkPayrollVisible,
      closeCheckPayroll,
      payrollState,
      closePayrollPage,
      onDelete,
      deletelPayable,
      deletelBuckle,
      belongProject
    };
  }

});