import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick, createVNode } from 'vue';
import useTablePagination from '@/hooks/pagination';
import { selectPayrollDetailByPagination, selectPayrollDetailById, updateSendOrWithdrawn } from '@/api/personnel';
import { useStore } from 'vuex';
const columns = [{
  title: '姓名',
  dataIndex: 'userName'
}, {
  title: '手机号',
  dataIndex: 'userMobile'
}, {
  title: '邮箱',
  dataIndex: 'companyEmail'
}, {
  title: '实发金额',
  dataIndex: 'netpayAmount',
  align: 'center'
}, {
  title: '发布时间',
  dataIndex: 'publishedTime',
  align: 'center',
  slots: {
    customRender: 'publishedTime'
  }
}, {
  title: '撤回时间',
  dataIndex: 'withdrawnTime',
  align: 'center',
  slots: {
    customRender: 'withdrawnTime'
  }
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default {
  props: {
    payrollState: {
      type: Object,
      default: {
        payrollName: '工资单',
        salaryMonth: '',
        id: null
      }
    }
  },
  emits: ['closePayrollPage'],

  setup(props, context) {
    const handleBack = () => {
      context.emit('closePayrollPage', false);
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const tableState = reactive({
      selectedRowKeys: [],
      tableLoading: false
    });

    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        userName: name.value,
        payrollId: props.payrollState.id
      };
      tableState.tableLoading = true;

      try {
        let res = await selectPayrollDetailByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          tableState.tableLoading = false;
        } else {
          tableState.tableLoading = false;

          _message.error(res.message);
        }
      } catch {
        tableState.tableLoading = false;
      }
    };

    const onSelectChange = selectedRowKeys => {
      // console.log('selectedRowKeys changed: ', selectedRowKeys);
      tableState.selectedRowKeys = selectedRowKeys;
    };

    const hasSelected = computed(() => tableState.selectedRowKeys.length > 0);
    const name = ref("");

    const onQuery = () => {
      current.value = 1;
      getTableData();
    }; //查看


    const detailInfo = reactive({
      userName: '',
      netpayAmount: '',
      payableDataList: [],
      deductibleDataList: [],
      remark: null
    });
    const visible = ref(false);

    const toView = async val => {
      // console.log(11111,val)
      try {
        let res = await selectPayrollDetailById(val.id);

        if (res.code === 200) {
          detailInfo.userName = res.data.userName;
          detailInfo.netpayAmount = res.data.netpayAmount;
          detailInfo.remark = res.data.remark;
          detailInfo.payableDataList = [];
          detailInfo.deductibleDataList = [];

          if (res.data.payableDataList) {
            if (res.data.payableDataList.length) {
              let snum = 0;
              res.data.payableDataList.forEach(item => {
                snum += Number(item.value);
                detailInfo.payableDataList.push(item);
              });
              detailInfo.payableDataList.unshift({
                label: '应付项',
                value: snum
              });
            } else {
              detailInfo.payableDataList.push({
                label: '应付项',
                value: '0'
              });
            }
          }

          if (res.data.deductibleDataList) {
            if (res.data.deductibleDataList.length) {
              let snum = 0;
              res.data.deductibleDataList.forEach(item => {
                snum += Number(item.value);
                detailInfo.deductibleDataList.push(item);
              });
              detailInfo.deductibleDataList.unshift({
                label: '应扣项',
                value: snum
              });
            } else {
              detailInfo.deductibleDataList.push({
                label: '应扣项',
                value: '0'
              });
            }
          }

          visible.value = true;
        } else {
          _message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);

    const handleSend = async () => {
      let isOk = true;
      tableState.selectedRowKeys.forEach(id => {
        tableData.value.forEach(item => {
          if (item.id === id && item.state === 1) {
            isOk = false;
            return;
          }
        });
      });

      if (!isOk) {
        _message.error('所选项中包含已发布项，请重新选择！');

        return;
      }

      loading.value = true;

      try {
        let param = {
          ids: tableState.selectedRowKeys.join(','),
          type: 1
        };
        let res = await updateSendOrWithdrawn(param);

        if (res.code === 200) {
          loading.value = false;

          _message.success(res.message);

          tableState.selectedRowKeys = [];
          getTableData();
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch (error) {
        loading.value = false;
        console.log(error);
      }
    };

    const handleWithdraw = async () => {
      let isOk = true;
      tableState.selectedRowKeys.forEach(id => {
        tableData.value.forEach(item => {
          if (item.id === id && item.state === 0) {
            isOk = false;

            _message.error('所选项中包含未发布项，请重新选择！');

            return;
          }

          if (item.id === id && item.state === 2) {
            isOk = false;

            _message.error('所选项中包含已撤回项，请重新选择！');

            return;
          }
        });
      });

      if (!isOk) {
        return;
      }

      loading.value = true;

      try {
        let param = {
          ids: tableState.selectedRowKeys.join(','),
          type: 2
        };
        let res = await updateSendOrWithdrawn(param);

        if (res.code === 200) {
          loading.value = false;

          _message.success(res.message);

          tableState.selectedRowKeys = [];
          getTableData();
        } else {
          loading.value = false;

          _message.error(res.message);
        }
      } catch (error) {
        loading.value = false;
        console.log(error);
      }
    };

    onMounted(() => {
      getTableData();
    });
    return {
      handleBack,
      columns,
      tableData,
      loading,
      onPageChange,
      paginationConfig,
      onSelectChange,
      name,
      onQuery,
      hasSelected,
      ...toRefs(tableState),
      toView,
      detailInfo,
      visible,
      isCompanyAdmin,
      handleSend,
      handleWithdraw
    };
  }

};