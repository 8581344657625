import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch, nextTick, createVNode } from 'vue';
import useTablePagination from '@/hooks/pagination';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import Cookies from "js-cookie";
import { insertPayroll, readPayrollExcel } from '@/api/personnel';
export default {
  components: {
    QuestionCircleOutlined,
    ExclamationCircleOutlined,
    uploadImgs
  },
  props: {
    checkPayrollVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeCheckPayroll'],

  setup(props, context) {
    //创建工资单
    // const checkPayrollVisible = ref(false)
    const formRefForCheckPayroll = ref();
    const formStateForCheckPayroll = reactive({
      payrollName: null,
      salaryMonth: null,
      payrollUrl: []
    });
    const rules = {
      payrollName: [{
        required: true,
        message: '工资单名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      salaryMonth: [{
        required: true,
        message: '发薪月份不能为空',
        trigger: 'change'
      }],
      payrollUrl: [{
        required: true,
        message: '工资单不能为空',
        type: 'array',
        trigger: 'change'
      }]
    };

    const handleCancelSetPayroll = val => {
      if (val === 1) {
        // checkPayrollVisible.value = false
        context.emit('closeCheckPayroll', false);
        formRefForCheckPayroll.value.resetFields();
      } else {
        releasePayrollState.checked = false;
        releasePayrollState.checked1 = false;
        releasePayrollState.note = null;
        formRefForCheckPayroll.value.resetFields();
        releasePayrollVisible.value = false; // checkPayrollVisible.value = false

        context.emit('closeCheckPayroll', false);
      }
    };

    const checkPayrollLoading = ref(false);
    const releasePayrollVisible = ref(false);
    const columnsForReleasePayroll = ref([]);
    const tableDataForReleasePayroll = ref([]);

    const handleOkCheckPayroll = () => {
      formRefForCheckPayroll.value.validate().then(async () => {
        checkPayrollLoading.value = true;

        try {
          getReleasePayrollTableData().then(res => {
            if (res) {
              releasePayrollVisible.value = true;
              checkPayrollLoading.value = false;
            } else {
              checkPayrollLoading.value = false;
            }
          });
        } catch (error) {
          checkPayrollLoading.value = false;
        }
      });
    }; //创建工资单分页信息


    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击创建工资单分页

    const onPageChangeForReleasePayroll = pagination => {
      current.value = pagination.current;
      getReleasePayrollTableData();
    };

    const releasePayrollTableloading = ref(false);

    const getReleasePayrollTableData = () => {
      return new Promise((resolve, reject) => {
        const param = {
          pageNumber: current.value,
          limit: pageSize.value ? pageSize.value : 10,
          payrollUrl: JSON.stringify(formStateForCheckPayroll.payrollUrl)
        };
        releasePayrollTableloading.value = true;
        readPayrollExcel(param).then(res => {
          try {
            if (res.code === 200) {
              const arr = [];
              tableDataForReleasePayroll.value = [];
              columnsForReleasePayroll.value = [{
                title: '姓名',
                dataIndex: 'userName',
                fixed: 'left',
                width: 80
              }, {
                title: '实发金额',
                dataIndex: 'netpayAmount',
                fixed: 'left',
                width: 100
              }, {
                title: '手机号',
                dataIndex: 'userMobile',
                width: 200
              }, {
                title: '邮箱',
                dataIndex: 'companyEmail',
                width: 200
              }];

              if (res.data.list && res.data.list.length) {
                res.data.list.forEach((item, index) => {
                  let obj = { ...item
                  };

                  if (item.payableDataList && item.payableDataList.length) {
                    item.payableDataList.forEach(ixt => {
                      obj[ixt.label] = ixt.value;

                      if (index === 0) {
                        columnsForReleasePayroll.value.push({
                          title: ixt.label,
                          dataIndex: ixt.label,
                          width: 200
                        });
                      }
                    });
                  }

                  if (item.deductibleDataList && item.deductibleDataList.length) {
                    item.deductibleDataList.forEach(ixt => {
                      obj[ixt.label] = ixt.value;

                      if (index === 0) {
                        columnsForReleasePayroll.value.push({
                          title: ixt.label,
                          dataIndex: ixt.label,
                          width: 200
                        });
                      }
                    });
                  }

                  arr.push(obj);
                });
              }

              Object.assign(tableDataForReleasePayroll.value, arr);
              total.value = res.data.countSize;
              current.value = res.data.nowPage;
              pageSize.value = res.data.pageSize;
              releasePayrollTableloading.value = false;
              resolve(true);
            } else {
              _message.error(res.message);

              resolve(false);
            }
          } catch (error) {
            resolve(false);
            console.log(error);
            releasePayrollTableloading.value = false;
          }
        }).catch(error => {
          resolve(false);
          console.log('查询历史请假记录', error);
          releasePayrollTableloading.value = false;
        });
      });
    };

    const releasePayrollState = reactive({
      checked: false,
      checked1: false,
      note: null
    });

    const handleReturnCheckPayroll = () => {
      releasePayrollState.checked = false;
      releasePayrollState.checked1 = false;
      releasePayrollState.note = null;
      releasePayrollVisible.value = false;
    };

    const releasePayrollLoading = ref(false);

    const handleOkReleasePayroll = async () => {
      releasePayrollLoading.value = true;

      try {
        let param = {
          payrollName: formStateForCheckPayroll.payrollName,
          salaryMonth: formStateForCheckPayroll.salaryMonth,
          payrollUrl: JSON.stringify(formStateForCheckPayroll.payrollUrl),
          isHiddenNull: releasePayrollState.checked ? 1 : 0,
          isHiddenZero: releasePayrollState.checked1 ? 1 : 0,
          remark: releasePayrollState.note
        };
        let res = await insertPayroll(param);

        if (res.code === 200) {
          _message.success(res.message);

          releasePayrollLoading.value = false;
          releasePayrollVisible.value = false;
          context.emit('closeCheckPayroll', false);
        } else {
          releasePayrollLoading.value = false;

          _message.error(res.message);
        }
      } catch (error) {
        releasePayrollLoading.value = false;
      }
    }; //获取工资单上传的url


    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formStateForCheckPayroll.payrollUrl = val;
      formRefForCheckPayroll.value.validateFields(['payrollUrl']);
    };

    const belongProject = Number(Cookies.get("belongProject"));
    return {
      // checkPayrollVisible,
      handleCancelSetPayroll,
      formRefForCheckPayroll,
      formStateForCheckPayroll,
      rules,
      getAdjuncts,
      handleOkCheckPayroll,
      checkPayrollLoading,
      releasePayrollVisible,
      onPageChangeForReleasePayroll,
      paginationConfig,
      columnsForReleasePayroll,
      releasePayrollTableloading,
      tableDataForReleasePayroll,
      releasePayrollState,
      handleReturnCheckPayroll,
      handleOkReleasePayroll,
      releasePayrollLoading,
      belongProject,
      total
    };
  }

};